import { useState } from "react";

function MobileSlider({currentFrame, setCurrentFrame, max}){

    const [currentAngle, setCurrentAngle] = useState(0);

    function angle(cx, cy, ex, ey) {
        var dy = ey - cy;
        var dx = ex - cx;
        var theta = Math.atan2(dy, dx); // range (-PI, PI]
        theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
        return theta + 90;
    }
    function angle360(cx, cy, ex, ey) {
        var theta = angle(cx, cy, ex, ey); // range (-180, 180]
        if (theta < 0) theta = 360 + theta; // range [0, 360)
        return theta;
    }


    const handleDrag = (e) => {

        const [mouseX, mouseY] = [e.touches[0].clientX, e.touches[0].clientY];
        const {x, y, width, height} = document.querySelector(".mobile-slider").getBoundingClientRect();

        const mouseAngle = angle360(x+width/2, y+height/2, mouseX, mouseY);
        const direction = ((mouseAngle - currentAngle + 540) % 360 - 180 < 0) ? -1 : 1;
        
        if ( Math.abs((mouseAngle - currentAngle + 540) % 360 - 180) < 20) 
            return;
    
        setCurrentAngle(mouseAngle);
        document.querySelector(".mobile-slider-thumb-wrapper").style.transform = `rotate(${mouseAngle}deg)`;
        
        if (currentFrame + direction >= max || currentFrame + direction < 0)
            return;
        
        setCurrentFrame(currentFrame + direction);
    }

    return (
        <div className="mobile-slider" style={{background: "radial-gradient(closest-side, #eee 90%, transparent 0, transparent 0), conic-gradient(#333 "+ (currentFrame/(max-1))*100 +"%, white 0)"}}>
            <div className="mobile-slider-thumb-wrapper">
                <div className="mobile-slider-thumb" draggable onDrag={handleDrag} onTouchMove={handleDrag}></div>
            </div>
        </div>
    );
}

export default MobileSlider;