import { useEffect, useRef, useState } from 'react';
import {format, parse, subDays, addDays, isSameDay, closestIndexTo} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import '../Assets/info.css';

import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from 'react-datepicker';


function SnowTotalsScale(props){
    return (
        <div>
            <h3 className="metric">in</h3>
            <div className="dbScale">
                <p style={{"backgroundColor": "#9dfcff"}}>{"< 1"}</p>
                <p style={{"backgroundColor": "#62e9ff"}}>1-3</p>
                <p style={{"backgroundColor": "#45d7f5"}}>3-5</p>
                <p style={{"backgroundColor": "#dba1ff"}}>5-8</p>
                <p style={{"backgroundColor": "#cf82ff"}}>8-12</p>
                <p style={{"backgroundColor": "#9d49f0"}}>12-18</p>
                <p style={{"backgroundColor": "#ff4cb9"}}>18-24</p>
                <p style={{"backgroundColor": "#ff78d4"}}>2-3 ft</p>
                <p style={{"backgroundColor": "#ffa5f3"}}>3-4 ft</p>
                <p style={{"backgroundColor": "#ffe2fa"}}>4+ ft</p>
            </div>
        </div>
    );
}
function CompositeReflectivityScale(props){
    return (
        <div>
            <h3 className="metric">dB</h3>
            <div className="dbScale">
                <p style={{"backgroundColor": "#c80000"}}>50</p>
                <p style={{"backgroundColor": "#ff1400"}}>45</p>
                <p style={{"backgroundColor": "#ff6c00"}}>40</p>
                <p style={{"backgroundColor": "#ffc900"}}>35</p>
                <p style={{"backgroundColor": "#2d7310"}}>30</p>
                <p style={{"backgroundColor": "#368b13"}}>25</p>
                <p style={{"backgroundColor": "#4bb222"}}>20</p>
                <p style={{"backgroundColor": "#48e20c"}}>15</p>
                <p style={{"backgroundColor": "#99ff71"}}>10</p>
                <p style={{"backgroundColor": "#afff95"}}>5</p>
            </div>
            <div className="dbScale">
                <p style={{"backgroundColor": "#a25dff"}}>50</p>
                <p style={{"backgroundColor": "#7956ff"}}>45</p>
                <p style={{"backgroundColor": "#625aff"}}>40</p>
                <p style={{"backgroundColor": "#576cff"}}>35</p>
                <p style={{"backgroundColor": "#4c88ff"}}>30</p>
                <p style={{"backgroundColor": "#3aa1ff"}}>25</p>
                <p style={{"backgroundColor": "#2bb7ff"}}>20</p>
                <p style={{"backgroundColor": "#03caff"}}>15</p>
                <p style={{"backgroundColor": "#3ad9ff"}}>10</p>
                <p style={{"backgroundColor": "#88eaff"}}>5</p>
            </div>
        </div>
    );
}

function Info({currentModel, currentFrame, timestamps}){

    const [visible, setVisible] = useState(false);
    const isSnowTotals = currentModel.name == "NDFD";

    return (
        <div className="info">
            <button id="info-button" onClick={() => setVisible(!visible)}>i</button>

            {
            visible && <div id="info-modal">
                <div id="dbIndicator">
                    {isSnowTotals ? <SnowTotalsScale /> : <CompositeReflectivityScale />}
                </div>

                <div className="keyboardKey"><p><span>Space</span> Play Animation</p></div>
                <div className="keyboardKey"><p><span>W</span> Next Day</p></div>
                <div className="keyboardKey"><p><span>S</span> Previous Day</p></div>
                <div className="keyboardKey"><p><span>D</span> Next Frame</p></div>
                <div className="keyboardKey"><p><span>A</span> Previous Frame</p></div>

                <div id="details">
                    <p>Model: {currentModel.name}</p>
                    <p>Runtime: {currentModel.dir}</p>
                    <p>Frame: {currentFrame}/{timestamps.length - 1}</p>
                    <p>{isSnowTotals ? "Type: Snowfall Totals" : "Type: Composite Radar Reflectivity"}</p>
                </div>
            </div>
            }
        </div>
    );
}



function Navigation({models, currentModel, currentFrame, setCurrentModel, setCurrentFrame}){
    
    const playbackRequest = useRef();
    const playbackStart = useRef(0);
    const playbackSpeed = useRef(50);
    const playback = useRef(false);
    
    const [calendarIsVisible, setCalendarIsVisible] = useState(false);
    
    const timestamps = currentModel.timestamps.map( ts => zonedTimeToUtc( parse(ts, 'yyyy-MM-dd HH:mm:ss', new Date()), 'Z') );
    const currentDate = timestamps[currentFrame];
    const sameDayFrames = timestamps.map((dte, i) => i);
    const timeSliderVisible = timestamps.length > 1;
    // const sameDayFrames = timestamps.map((dte, i) => i).filter(i => isSameDay(timestamps[i], currentDate));
    
    const nextFrame = () => (currentFrame >= timestamps.length-1) ? timestamps.length-1 : (currentFrame + 1);
    const previousFrame = () => (currentFrame <= 0) ? 0 : (currentFrame - 1);    
    const downHandler = ({keyCode}) => {
        if (keyCode === 68) setCurrentFrame(nextFrame());
        if (keyCode === 65) setCurrentFrame(previousFrame());
        if (keyCode === 87) handleNextButtonClick();
        if (keyCode === 83) handlePrevButtonClick();
        if (keyCode === 32) handlePlayButtonClick();
    }
    useEffect(() => {
        document.addEventListener('keydown', downHandler);
        return () => {
            document.removeEventListener('keydown', downHandler);
        }
    });

    const handleModelChange = e => {
        playback.current = false;
        const idx = e.currentTarget.value;
        e.currentTarget.blur();
        setCurrentModel(models[idx]);
        setCurrentFrame(0);
    }

    const handleTimeChange = e => {
        setCurrentFrame(parseInt(e.currentTarget.value));
    }
    const handleDateChange = e => {
        // let date = parse(e.currentTarget.value + format(currentDate, ' HH:mm'), 'yyyy-MM-dd HH:mm', new Date());
        let date = e;
        setDate(date);
    }
    const handlePrevButtonClick = e => {
        setDate(subDays(currentDate, 1));
    }
    const handleNextButtonClick= e => {
        setDate(addDays(currentDate, 1));
    }
    const handlePlayButtonClick = e => {
        playback.current = !playback.current;
        playbackAnimation();
    }
    const setDate = date => {
        if (date < timestamps[0]) 
            date = timestamps[0];
        if (date > timestamps[timestamps.length-1]) 
            date = timestamps[timestamps.length-1];

        setCurrentFrame( closestIndexTo(date, timestamps) );
    }
    
    const increasePlaybackSpeed = () => {
        if (playbackSpeed.current <= 20) return;
        playbackSpeed.current -= 20;
    }
    const decreasePlaybackSpeed = () => {
        if (playbackSpeed.current >= 300) return;
        playbackSpeed.current += 20;
    }
    const playbackAnimation = time => {
        if (!playback.current) return;

        const elapsed = time - playbackStart.current;
        if (elapsed && elapsed > playbackSpeed.current){
            setCurrentFrame(frame => (frame + 1) % timestamps.length);
            playbackStart.current = time;
        }
        playbackRequest.current = requestAnimationFrame(playbackAnimation);
    }

    return (
        <nav onMouseEnter={e => setCalendarIsVisible(true)} onMouseLeave={e => setCalendarIsVisible(false)}>

            <select id="modelBox" onChange={handleModelChange}>
                {models.map((model, i) => 
                    <option key={i} value={i} name={model.name}>{model.name}</option>
                )}
            </select>
            
            <div id="dateBox-wrapper">
                <button id="previous-button" className="left-button" onClick={handlePrevButtonClick}>{"<"}</button>
                <p id='dateBox-timezone'>{format(currentDate, "z")}</p>
                <button id="react-datepicker-button"><time dateTime={format(currentDate, 'yyyy-MM-dd hh:mm')}></time>{format(currentDate, "MMM d yyyy HH:mm")}</button>
                {calendarIsVisible && <ReactDatePicker
                    selected={currentDate}
                    dateFormat="MMMM dd, yyyy HH:mm"
                    onChange={(e) => {handleDateChange(e);}}
                    minDate={timestamps[0]}
                    maxDate={timestamps[timestamps.length-1]}
                    inline
                />}
                <button id="next-button" className="right-button" onClick={handleNextButtonClick}>{">"}</button>
            </div>

            {timeSliderVisible && <div id="timeBox">
                <input id="timeBox-slider" onChange={handleTimeChange} type="range" step="1" min={sameDayFrames[0]} max={sameDayFrames[sameDayFrames.length-1]} value={currentFrame} />
                {sameDayFrames.length < 5 && <div className="sliderticks">{sameDayFrames.map((ts, i) => <p key={i}></p>)}</div> }
            </div>}

            {timeSliderVisible && <div id="speedBox">
                <button id="slower-button" className="left-button" onClick={decreasePlaybackSpeed}><img className="label" alt="turtle" src='http://snowdar.com/style/assets/turtle.png' /></button>
                <button id="playback-button" type="text" onClick={handlePlayButtonClick}>Play</button>
                <button id="faster-button" className="right-button" onClick={increasePlaybackSpeed}><img className="label" alt="bunny" src='http://snowdar.com/style/assets/bunny.png' /></button>
            </div>}

            <Info currentModel={currentModel} currentFrame={currentFrame} timestamps={timestamps} />

        </nav>
    );
}

export default Navigation;