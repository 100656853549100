import { useState, useEffect } from 'react';
import Navigation from './Hooks/Navigation';
import Map from './Hooks/Map';
import Loading from './Hooks/Loading';
import MobileSlider from './Hooks/MobileSlider';
import './App.css';
import 'normalize.css';

// import models from './Debug/models.json';

function ModelView({models}){

  const [currentModel, setCurrentModel] = useState(models[0]);
  const [currentFrame, setCurrentFrame] = useState(0);

  return (
    <div>
      <Navigation models={models} currentModel={currentModel} setCurrentModel={setCurrentModel} currentFrame={currentFrame} setCurrentFrame={setCurrentFrame} />
      <Map currentModel={currentModel} currentFrame={currentFrame} />
      <MobileSlider currentFrame={currentFrame} setCurrentFrame={setCurrentFrame} max={currentModel.timestamps.length} />
    </div>
  );
}

function App(){
  
  // const models = [
  //   {name: "GFS", dir: "GFS_2022110612Z/glble", resolution: [1246, 966], coordinates: [[-180, 80], [180, 80], [180, -80], [-180, -80]], timestamps: ["2022-11-06 12:00", "2022-11-06 13:00", "2022-11-06 14:00", "2022-11-06 15:00", "2022-11-06 16:00", "2022-11-06 17:00", "2022-11-06 18:00", "2022-11-06 19:00", "2022-11-06 20:00", "2022-11-06 21:00", "2022-11-06 22:00", "2022-11-06 23:00", "2022-11-07 00:00", "2022-11-07 01:00", "2022-11-07 02:00", "2022-11-07 03:00", "2022-11-07 04:00", "2022-11-07 05:00", "2022-11-07 06:00", "2022-11-07 07:00", "2022-11-07 08:00", "2022-11-07 09:00", "2022-11-07 10:00", "2022-11-07 11:00", "2022-11-07 12:00", "2022-11-07 13:00", "2022-11-07 14:00", "2022-11-07 15:00", "2022-11-07 16:00", "2022-11-07 17:00", "2022-11-07 18:00", "2022-11-07 19:00", "2022-11-07 20:00", "2022-11-07 21:00", "2022-11-07 22:00", "2022-11-07 23:00", "2022-11-08 00:00", "2022-11-08 01:00", "2022-11-08 02:00", "2022-11-08 03:00"]},
  //   {name: "Taco", dir: "GFS_2022110612Z/glble", resolution: [1246, 966], coordinates: [[-180, 60], [180, 60], [180, -60], [-180, -60]], timestamps: ["2022-11-06 12:00", "2022-11-06 13:00", "2022-11-06 14:00", "2022-11-06 15:00", "2022-11-06 16:00", "2022-11-06 17:00", "2022-11-06 18:00", "2022-11-06 19:00", "2022-11-06 20:00", "2022-11-06 21:00", "2022-11-06 22:00", "2022-11-06 23:00", "2022-11-07 00:00", "2022-11-07 01:00", "2022-11-07 02:00", "2022-11-07 03:00", "2022-11-07 04:00", "2022-11-07 05:00", "2022-11-07 06:00", "2022-11-07 07:00", "2022-11-07 08:00", "2022-11-07 09:00", "2022-11-07 10:00", "2022-11-07 11:00", "2022-11-07 12:00", "2022-11-07 13:00", "2022-11-07 14:00", "2022-11-07 15:00", "2022-11-07 16:00", "2022-11-07 17:00", "2022-11-07 18:00", "2022-11-07 19:00", "2022-11-07 20:00", "2022-11-07 21:00", "2022-11-07 22:00", "2022-11-07 23:00", "2022-11-08 00:00", "2022-11-08 01:00", "2022-11-08 02:00", "2022-11-08 03:00"]}
  // ];
  // const [models, setModels] = useState([{name: "GFS", dir: "GFS_2022110612Z/glble", resolution: [1246, 966], coordinates: [[-180, 80], [180, 80], [180, -80], [-180, -80]], timestamps:["2022-11-06 12:00:00"]}]);

  const [models, setModels] = useState([]);
  useEffect(() => {
    const fetchModels = async () => {      
      fetch("./models.php")
        .then(r => r.json())
        .then(j => setModels(j));
    }

    fetchModels();
  }, []);
  
  return (
    <div className="App">
      {models.length ? <ModelView models={models} /> : <Loading />}
    </div>
  );
}

export default App;
