import '../Assets/loading.css';

function Loading(props){
    return (
      <div className="loading">
        <div className='snowballs'>
            <div className="snowball"></div>
            <div className="snowball"></div>
            <div className="snowball"></div>
            <div className="snowball"></div>
            <div className="snowball"></div>
        </div>
        {/* <h1>Loading</h1> */}
      </div>
    );
}

export default Loading;