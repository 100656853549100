import { useRef, useState, useEffect } from "react";
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import mapboxStyle from "../Config/mapboxStyle.json";
import { zonedTimeToUtc } from 'date-fns-tz';
import {format, parse, subDays, addDays, isSameDay, closestIndexTo} from 'date-fns';

import '../Assets/map.css';

function Map({currentModel, currentFrame}){

    const map = useRef(null);
    const mapContainer = useRef(null);
    const canvas = useRef(null);
    const [canvasContext, setCanvasContext] = useState(null);

    const [images, setImages] = useState([]);
    const drawFrame = () => {
      canvas.current.getContext("2d").clearRect(0, 0, width, height);
      canvas.current.getContext("2d").drawImage(images[currentFrame], 0, 0);
    }

    useEffect(() => {
        if (map.current) return;
        mapboxgl.accessToken = 'pk.eyJ1IjoibWUwMGxtZWFscyIsImEiOiJja2Fza2htbmQwNnhrMnducnF6czFyNjdxIn0.0yJW0KVDrKqWzOX26sjT2A';
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: mapboxStyle,
            pitch: 20,
            maxPitch: 65,
            minZoom: 1
        });

        map.current.loadImage('./style/assets/rounded.png', function(error, image) {
          if (error) throw error;
          if (!map.current.hasImage('rounded')) {
              map.current.addImage('rounded', image, {
                  content: [7, 3, 9, 13],
                  stretchX: [[7, 9]],
                  stretchY: [[7, 9]]
              });
          }
        });
        
        map.current.on('load', () => {
            setCanvasContext(canvas.current.getContext("2d"));
        });
    }, []);

    useEffect(() => {
        if(!canvasContext || images.length <= 0) return;

        const frameImage = images[currentFrame];
        const timestampHours = currentModel.timestamps.map( ts => zonedTimeToUtc( parse(ts, 'yyyy-MM-dd HH:mm:ss', new Date()), 'Z').getHours() );
        if (!frameImage.complete){
          frameImage.onload = drawFrame;
        }
        else{
          const currentHour = timestampHours[currentFrame];
          map.current.setPaintProperty("background", "background-color", (currentHour < 7 || currentHour > 19) ? "#bbbbbb" : "#f0f0f0");
          drawFrame();
        }

    }, [canvasContext, images, currentFrame, currentModel]);

    useEffect(() => {
        if(!canvasContext) return;
        map.current.getSource('us-source').setCoordinates(currentModel.coordinates);
        map.current.setMaxBounds([ [currentModel.coordinates[3][0] - 20, currentModel.coordinates[3][1] - 10], [currentModel.coordinates[1][0] + 20, currentModel.coordinates[1][1] + 10] ]);
        map.current.getSource('mask').setData({
            "type": "FeatureCollection",
            "features": [
              {
                "type": "Feature",
                "properties": {},
                "geometry": {
                  "type": "Polygon",
                  "coordinates": [
                    [
                      [currentModel.coordinates[1][0], currentModel.coordinates[2][1]],
                      [currentModel.coordinates[1][0], currentModel.coordinates[1][1]],
                      [360 + currentModel.coordinates[0][0], currentModel.coordinates[1][1]],
                      [360 + currentModel.coordinates[0][0], currentModel.coordinates[2][1]],
                      [currentModel.coordinates[1][0], currentModel.coordinates[2][1]]
                    ]
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {},
                "geometry": {
                  "type": "Polygon",
                  "coordinates": [
                    [
                      [-180, currentModel.coordinates[1][1]],
                      [-180, 90],
                      [180, 90],
                      [180, currentModel.coordinates[1][1]],
                      [-180, currentModel.coordinates[1][1]]
                    ]
                  ]
                }
              },
              {
                "type": "Feature",
                "properties": {},
                "geometry": {
                  "type": "Polygon",
                  "coordinates": [
                    [
                      [-180, -90],
                      [-180, currentModel.coordinates[2][1]],
                      [180, currentModel.coordinates[2][1]],
                      [180, -90],
                      [-180, -90]
                    ]
                  ]
                }
              }
            ]
          }
        );

        const newImages = Array(currentModel.timestamps.length).fill(0).map((_, i) => {
          let img = new Image();
          img.src = `../models/REFC/${ currentModel.dir }/refc${ i }.png`;
          
          return img;
        });
        setImages(newImages);

    }, [canvasContext, currentModel]);

    

    const [width, height] = currentModel.resolution;
    
    return (
        <div>
            <div id="mapContainer" ref={mapContainer}></div>
            <canvas id="display" ref={canvas} width={width} height={height} style={{"display": "none"}}></canvas>
        </div>
    );
}

export default Map;